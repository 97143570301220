import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import Drawer from '@material-ui/core/Drawer'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import StorageIcon from '@material-ui/icons/Storage'
import Create from '@material-ui/icons/Create'
import Upload from '@material-ui/icons/CloudUpload'
import NewReleases from '@material-ui/icons/NewReleases'
import { green } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import FlexView from 'react-flexview'

import Servers from './Servers'
import Console from './Console'
import ServerView from './ServerView'

import axiosWithRateLimit from '../axiosWithRateLimit'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import './Home.css'
import { getServerRepoName } from '../tokenUtils'

const theme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        color: 'inherit',
        display: 'table !important',
        width: '100%'
      }
    }
  }
})

const drawerWidth = 240
const viewStyles = theme => ({
  root: {
    marginLeft: 240,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  deployButton: {
    marginLeft: 20
  },
  toolbar: theme.mixins.toolbar,
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    marginTop: theme.spacing(8),
    flexDirection: 'column'
  },
  deployContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400
  },
  button: {
    margin: theme.spacing(5)
  },
  tableWrapper: {
    width: '60vw',
    marginTop: theme.spacing(3)
  },
  tableBody: {
    overflowY: 'scroll',
    display: 'block !important',
    height: '35vh'
  },
  drawerPaper: {
    width: drawerWidth
  },
  highlight: {
    color: 'white',
    minHeight: '30px',
    backgroundColor: green[300]
  },
  commitInfo: {
    backgroundColor: '#81c784',
    color: 'white',
    fontSize: '16px',
    marginTop: theme.spacing(3),
    padding: '10px',
    fontWeight: '600',
    textAlign: 'center'
  },
  title: {
    flexGrow: 1,
    fontSize: '20px',
    fontWeight: 600
  },
  tabeRoot: {
    display: 'table !important',
    width: '100%'
  },
  serviceContainer: {
    marginLeft: '10vw',
    marginRight: '10vw',
    marginTop: '4vh'
  },
  servicePaper: {
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
    padding: '4px'
  },
  servicePort: {
    marginRight: '10px',
    color: 'white',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    backgroundColor: '#66bb69',
    padding: '6px 16px',
    fontSize: '0.875rem',
    minWidth: '64px',
    boxSizing: 'border - box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    margin: theme.spacing(5),
    color: green[500],
    position: 'absolute'
  },
  buttonProgressDeploy: {
    margin: theme.spacing(5),
    color: '#ffffff',
    position: 'absolute'
  },
  dropDown: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  }
})

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red'
      },
      '&:hover fieldset': {
        borderColor: 'yellow'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green'
      }
    }
  }
})(TextField)

let tabNameServerRepoName = getServerRepoName()
const tabNameHercules = 'hercules'
const tabNameTesseract = 'tesseract'

class Home extends Component {
  state = {
    anchorEl: null,
    selectedIndex: 0,
    open: false,
    activeStep: 0,
    activeDrawer: 'create-release',
    name: '',
    loading: false,
    activeServer: '',
    indiServerView: false,
    commitHash: '',
    branch: '',
    selectedServers: 0,
    selectedServiceServers: 0,
    logs: [],
    activeCommit: {
      name: '',
      hash: '',
      branch: ''
    },
    activeCommitServer: {
      name: '',
      hash: '',
      branch: '',
      repoName: ''
    },
    activeCommitHercules: {
      name: '',
      hash: '',
      branch: '',
      repoName: ''
    },
    activeCommitTesseract: {
      name: '',
      hash: '',
      branch: '',
      repoName: ''
    },
    serverTypes: [
      'gameserver',
      'router',
      'userserver',
      'consumer',
      'statusserver',
      'statserver',
      'clubserver',
      'tesseract'
    ],
    servers: [],
    activeTab: tabNameServerRepoName
  }

  createRelease = () => {
    this.setState({ loading: true })
    axios
      .post('/prepare-release', {
        name: this.state.name,
        repoName: this.state.activeTab,
        commitHash: this.state.commitHash,
        branch: this.state.branch
      })
      .then(json => json.data)
      .then(data => {
        if (data.done) {
          let state = Object.assign({}, this.state)
          state.activeStep = 1

          if (data.repoName == tabNameServerRepoName) {
            state.activeCommitServer.name = state.name
            state.activeCommitServer.hash = state.commitHash
            state.activeCommitServer.branch = state.branch
            state.activeCommitServer.repoName = data.repoName

            state.activeCommit = state.activeCommitServer
          } else if (data.repoName == tabNameHercules) {
            state.activeCommitHercules.name = state.name
            state.activeCommitHercules.hash = state.commitHash
            state.activeCommitHercules.branch = state.branch
            state.activeCommitHercules.repoName = data.repoName

            state.activeCommit = state.activeCommitHercules
          } else if (data.repoName == tabNameTesseract) {
            state.activeCommitTesseract.name = state.name
            state.activeCommitTesseract.hash = state.commitHash
            state.activeCommitTesseract.branch = state.branch
            state.activeCommitTesseract.repoName = data.repoName

            state.activeCommit = state.activeCommitTesseract
          }

          for (let chunk of data.chunk) {
            state.logs.push(chunk)
          }
          this.setState(state)
        } else {
          let state = Object.assign({}, this.state)
          for (let chunk of data.chunk) {
            state.logs.push(chunk)
          }
          this.setState(state)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => this.setState({ loading: false }))
  }

  revertServer = server => {
    axios
      .post('/revert-release', {
        server: server,
        repoName: this.state.activeTab
      })
      .then(resp => resp.data)
      .then(data => {
        let state = Object.assign({}, this.state)
        for (let chunk of data.chunk) {
          state.logs.push(chunk)
        }
        this.setState(state)
        this.updatePortForServer(server)
        this.updateSymlinksForServer(server)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => this.setState({ loading: false }))
  }

  isAlreadyReverted = server => {
    const { isReverted, isRevertedHercules } = server
    if (!this.isActiveTabServer()) {
      return isRevertedHercules !== undefined && isRevertedHercules === true
    } else {
      return isReverted !== undefined && isReverted === true
    }
  }

  deployRelease = () => {
    let servers = []
    let state = Object.assign({}, this.state)
    for (let server of state.servers) {
      if (server.selected == true) {
        servers.push(server)
      }
    }

    for (let server of servers) {
      if (!server.deployDone) {
        this.setState({ loading: true })

        let reqBody = {
          name: this.state.activeCommit.name,
          repoName: this.state.activeCommit.repoName,
          commitHash: this.state.activeCommit.hash,
          branch: this.state.activeCommit.branch,
          server: server
        }
        if (server.type == 'router') {
          for (let s of state.servers) {
            if (s.name == server.gameserverName) {
              reqBody.gameserverPort = s.port
            }
          }
        }

        this.updatePortForServer(server).then(() => {
          if (server.disabled) {
            const msg = `given server port not updated, skipping deployment, name: ${server.name}`
            state.logs.push({ type: 'error', msg: msg })
            alert(msg)
          } else {
            console.log(`updating port done, continuing deployment, name: ${server.name}`)
            axios
              .post('/deploy-release', reqBody)
              .then(json => json.data)
              .then(data => {
                if (data.done) {
                  let state = Object.assign({}, this.state)
                  for (let server of state.servers) {
                    if (server.name == data.server.name) {
                      server.releases = data.releases
                      server.deployDone = true
                      server.error = false
                      this.handleClick(null, server)
                      for (let link of server.symlinks) {
                        if (link.name == data.linkName) {
                          link.activeCommit = data.activeCommit
                        }
                      }
                      this.updatePortForServer(server)
                    }
                  }
                  for (let chunk of data.chunk) {
                    state.logs.push(chunk)
                  }
                  state.loading = false
                  this.setState(state)
                } else {
                  let state = Object.assign({}, this.state)
                  for (let s of state.servers) {
                    if (s.name == server.name) {
                      s.deployDone = false
                      s.error = true
                    }
                  }
                  for (let chunk of data.chunk) {
                    state.logs.push(chunk)
                  }
                  state.loading = false
                  this.setState(state)
                }
              })
              .catch(error => {
                console.log(error)
                let errStr = error.toString('utf8')
                let state = Object.assign({}, this.state)
                state.logs.push(errStr)
                state.loading = false
                this.setState(state)
              })
          }
        })
      } else {
        const msg = `already one deployment done on ${server.name}, please refresh page and build in case repo is same, trigger next deployment`
        state.logs.push({ type: 'error', msg: msg })
        alert(msg)
      }
    }
  }

  updateSymlinksForAllServers = () => {
    for (let server of this.state.servers) {
      this.updateSymlinksForServer(server)
    }
  }

  updateSymlinksForServer = server => {
    for (let link of server.symlinks) {
      axiosWithRateLimit
        .post('/symlink', {
          linkName: link.name,
          server: server
        })
        .then(resp => resp.data)
        .then(data => {
          let state = Object.assign({}, this.state)
          for (let server of state.servers) {
            if (server.name == data.name) {
              for (let link of server.symlinks) {
                if (link.name == data.linkName) {
                  link.activeCommit = data.activeCommit
                }
              }
            }
          }
          this.setState(state)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  updatePortForAllServers = () => {
    for (let server of this.state.servers) {
      this.updatePortForServer(server)
    }
  }

  updatePortForServer = server =>
    axios
      .post('/updatePort', { server })
      .then(json => json.data)
      .then(data => {
        let state = Object.assign({}, this.state)
        for (let server of state.servers) {
          if (server.name == data.name) {
            server.port = data.port
            server.versionCode = data.versionCode
            server.disabled = !data.portUpdated
          }
        }
        this.setState(state)
      })
      .catch(err => {
        console.log(`updatePortForServer: ${server.name}, error: ${err}`)
      })

  componentDidMount = () => {
    axios
      .get('/servers')
      .then(resp => resp.data)
      .then(data => {
        this.setState({ servers: data })
        this.updatePortForAllServers()
        this.updateSymlinksForAllServers()
      })
      .catch(err => {
        console.log(err)
      })

    axios
      .get(`/buildcommit/${tabNameServerRepoName}`)
      .then(resp => resp.data)
      .then(data => {
        if (data) {
          delete data._id
          this.setState({ activeCommitServer: data })
          // update activeCommit with activeCommitServer as default initial value
          this.setState({ activeCommit: this.state.activeCommitServer })
        }
      })
      .catch(err => {
        console.log(err)
      })

    axios
      .get('/buildcommit/hercules')
      .then(resp => resp.data)
      .then(data => {
        if (data) {
          delete data._id
          this.setState({ activeCommitHercules: data })
        }
      })
      .catch(err => {
        console.log(err)
      })

    axios
      .get('/buildcommit/tesseract')
      .then(resp => resp.data)
      .then(data => {
        if (data) {
          delete data._id
          this.setState({ activeCommitTesseract: data })
        }
      })
      .catch(err => {
        console.log(err)
      })
    this.setState({ activeTab: getServerRepoName() })
  }

  // handleClick toggles given server selection for deployment and
  // it also keeps count of selected servers
  handleClick = (event, name) => {
    const selectedIndex = this.state.servers.indexOf(name)
    let state = Object.assign({}, this.state)
    if (state.servers[selectedIndex].selected) {
      state.selectedServers--
    } else {
      state.selectedServers++
    }
    state.servers[selectedIndex].selected = !state.servers[selectedIndex].selected
    this.setState(state)
  }

  selectAll = () => {
    let state = Object.assign({}, this.state)
    for (let row of state.servers) {
      console.log(row)
      row.selected = true
    }
    state.selectedServers = state.servers.length
    this.setState(state)
  }

  addServer = server => {
    axios
      .post('/server', server)
      .then(resp => resp.data)
      .then(data => {
        console.log(data)
        let state = Object.assign({}, this.state)
        let tempServer = Object.assign({}, data)
        tempServer.selected = false
        tempServer.releases = []
        tempServer.serviceSelected = false
        tempServer.loading = false
        state.servers.push(tempServer)
        this.setState(state)
      })
      .catch(err => {
        console.log(err)
      })
  }

  changeMultiTraffic = () => {
    for (let server of this.state.servers) {
      if (server.serviceSelected) {
        this.changeTraffic(server)
      }
    }
  }

  changeTraffic = server => {
    axios
      .post('/change-traffic', {
        server: server
      })
      .then(resp => resp.data)
      .then(data => {
        let state = Object.assign({}, this.state)
        for (let chunk of data.chunk) {
          state.logs.push(chunk)
        }
        this.setState(state)
        axios
          .post('/updatePort', {
            server: data.server
          })
          .then(json => {
            return json.data
          })
          .then(data => {
            let state = Object.assign({}, this.state)
            for (let server of state.servers) {
              if (server.name == data.name) {
                server.port = data.port
                server.versionCode = data.versionCode
                server.loading = false
                server.blocked = true
              }
            }
            this.setState(server)
          })
          .catch(err => {
            console.log(err)
          })
      })
    let state = Object.assign({}, this.state)
    for (let lserver of state.servers) {
      if (lserver.name == server.name) {
        lserver.loading = true
      }
    }
    this.setState(server)
  }

  handleClickListItem = (event, index) => {
    this.setState({
      selectedIndex: index,
      anchorEl: event.currentTarget,
      open: true
    })
  }

  handleMenuItemClick = (event, index) => {
    this.setState({ selectedIndex: index, anchorEl: null, open: false })
  }

  handleClose = tabName => {
    this.setState({ activeTab: tabName, open: false })
    let activeCommit = this.state.activeCommitServer
    if (tabName == tabNameHercules) {
      activeCommit = this.state.activeCommitHercules
    } else if (tabName == tabNameTesseract) {
      activeCommit = this.state.activeCommitTesseract
    }
    this.setState({ activeCommit: activeCommit })
  }

  isActiveTabServer = () => {
    return this.state.activeTab == tabNameServerRepoName
  }

  getActiveView = () => {
    const { classes } = this.props
    let servers = this.state.servers

    switch (this.state.activeDrawer) {
      case 'create-release':
        return (
          <form
            className={classes.container}
            noValidate
            autoComplete='on'
          >
            <CssTextField
              id='release-name'
              label='Release Name'
              className={classes.textField}
              value={this.state.name}
              onChange={this.handleChange('name')}
              margin='normal'
              type='text'
            />
            <CssTextField
              id='commit-hash'
              label='Commit Hash'
              className={classes.textField}
              value={this.state.commitHash}
              onChange={this.handleChange('commitHash')}
              margin='normal'
              type='text'
            />
            <CssTextField
              id='branch-name'
              label='Branch'
              className={classes.textField}
              value={this.state.branch}
              onChange={this.handleChange('branch')}
              margin='normal'
              type='text'
            />
            <br />
            <br />
            <div className={classes.wrapper}>
              <Button
                style={{ flexGrow: 1 }}
                onClick={this.createRelease}
                variant='contained'
                disabled={this.state.loading}
                className={classes.button}
              >
                SUBMIT
              </Button>
              {this.state.loading && (
                <CircularProgress
                  style={{ top: '5%', left: '36%' }}
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </form>
        )
      case 'deploy-release':
        servers.sort((a, b) => (a.name > b.name ? 1 : -1))
        return (
          <div className={classes.deployContainer}>
            <ThemeProvider theme={theme}>
              <FlexView column>
                <Paper className={classes.commitInfo}>
                  {this.state.activeCommit.name} ({this.state.activeCommit.branch}
                  {', '}
                  {this.state.activeCommit.hash.substr(0, 6)})
                </Paper>

                <Paper className={classes.tableWrapper}>
                  <Toolbar className={classes.highlight}>
                    <div className={classes.title}>Servers</div>
                    <div className={classes.wrapper}>
                      <Button
                        onClick={this.deployRelease}
                        variant='contained'
                        disabled={this.state.selectedServers == 0 || this.state.loading}
                        className={classes.deployButton}
                      >
                        DEPLOY
                      </Button>
                      {this.state.loading && (
                        <CircularProgress
                          style={{ top: '-96%', left: '0%' }}
                          size={24}
                          className={classes.buttonProgressDeploy}
                        />
                      )}
                    </div>

                    <div className={classes.spacer} />
                  </Toolbar>
                  <Table className={classes.table}>
                    <TableBody className={classes.tableBody}>
                      {servers
                        .filter(server => {
                          if (this.state.activeTab === tabNameHercules) {
                            if (server.hasHercules) {
                              return server
                            }
                          } else if (this.state.activeTab == tabNameTesseract) {
                            if (server.type == 'tesseract') {
                              return server
                            }
                          } else {
                            return server
                          }
                        })
                        .map(row => (
                          <TableRow
                            className={classes.tabeRoot}
                            style={{ display: 'table !important', width: '100%' }}
                            key={row.name}
                            role='checkbox'
                          >
                            <TableCell padding='checkbox'>
                              <Checkbox
                                onClick={event => this.handleClick(event, row)}
                                checked={row.selected}
                                disabled={row.disabled && this.isActiveTabServer()}
                              />
                            </TableCell>
                            <TableCell
                              component='th'
                              style={{ padding: '6px' }}
                              scope='row'
                            >
                              {row.name}
                            </TableCell>
                            {row.disabled && this.isActiveTabServer() ? (
                              <TableCell
                                style={{
                                  padding: '6px',
                                  width: '120px',
                                  textAlign: 'center',
                                  color: 'red'
                                }}
                                align='left'
                              >
                                Port not updated
                              </TableCell>
                            ) : null}
                            {row.error ? (
                              <TableCell
                                style={{
                                  padding: '6px',
                                  width: '120px',
                                  textAlign: 'center'
                                }}
                                align='left'
                              >
                                <ErrorIcon style={{ color: 'red' }}></ErrorIcon>
                              </TableCell>
                            ) : null}
                            {row.deployDone ? (
                              <TableCell
                                style={{
                                  padding: '6px',
                                  width: '120px',
                                  textAlign: 'center'
                                }}
                                align='left'
                              >
                                <CheckCircleIcon style={{ color: 'green' }}></CheckCircleIcon>
                              </TableCell>
                            ) : null}
                            <TableCell
                              style={{
                                padding: '6px',
                                width: '120px',
                                paddingRight: '20px'
                              }}
                              align='right'
                            >
                              {row.type}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    {/* </div> */}
                  </Table>
                </Paper>
              </FlexView>
            </ThemeProvider>

            <br />
          </div>
        )
      case 'start-service':
        servers.sort((a, b) => (a.name > b.name ? 1 : -1))
        return (
          <div className={classes.serviceContainer}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px'
              }}
            ></div>
            <Grid
              container
              spacing={2}
            >
              {servers.map(server => {
                if (server.type == 'router') {
                  return (
                    <Grid
                      item
                      xs={6}
                    >
                      <Paper className={classes.servicePaper}>
                        <div
                          style={{
                            flexGrow: 1,
                            fontSize: '1rem',
                            marginLeft: '20px'
                          }}
                        >
                          {server.name}
                        </div>
                        <div className={classes.servicePort}>{server.port}</div>
                        <div className={classes.wrapper}>
                          <Button
                            onClick={event => this.changeTraffic(server)}
                            variant='contained'
                            disabled={server.loading || server.blocked}
                          >
                            Change
                          </Button>
                          {server.loading && (
                            <CircularProgress
                              style={{ top: '-95%', left: '-7%%' }}
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </div>
                      </Paper>
                    </Grid>
                  )
                }
              })}
            </Grid>
          </div>
        )
      case 'servers':
        if (this.state.indiServerView) {
          return (
            <div>
              <ServerView
                isAlreadyReverted={this.isAlreadyReverted}
                revertServer={this.revertServer}
                goBack={() => this.setState({ indiServerView: false })}
                server={this.state.activeServer}
                loading={this.state.loading}
              />
            </div>
          )
        } else {
          return (
            <Servers
              servers={this.state.servers}
              serverTypes={this.state.serverTypes}
              addServer={this.addServer}
              viewServer={this.viewServer}
            />
          )
        }
    }
  }

  viewServer = server => {
    this.setState({ indiServerView: true, activeServer: server })
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 })
  }

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 })
  }

  handleReset = () => {
    this.setState({ activeStep: 0 })
  }

  handleChange = name => event => {
    let state = Object.assign({}, this.state)
    state[name] = event.target.value
    this.setState(state)
  }

  render() {
    const { classes } = this.props

    return (
      <main className={classes.content}>
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant='permanent'
        >
          <div className={classes.toolbar}>
            <div className={classes.dropDown}>
              <Button
                id='demo-customized-button'
                variant='contained'
                disableElevation
                onClick={this.handleClickListItem}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {this.state.activeTab}
              </Button>
              <Menu
                id='demo-customized-menu'
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={this.state.anchorEl}
                open={this.state.open}
                onClose={() => {
                  this.handleClose(this.state.activeTab)
                }}
              >
                <MenuItem
                  onClick={() => {
                    this.handleClose(tabNameServerRepoName)
                  }}
                  disableRipple
                >
                  Magneto
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.handleClose(tabNameHercules)
                  }}
                  disableRipple
                >
                  Hercules
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.handleClose(tabNameTesseract)
                  }}
                  disableRipple
                >
                  Tesseract
                </MenuItem>
              </Menu>
            </div>
          </div>
          <Divider />
          <div className={classes.list}>
            <List>
              {' '}
              <ListItem
                style={{
                  backgroundColor: this.state.activeDrawer == 'create-release' ? '#eeeeee' : '#ffffff'
                }}
                onClick={() => this.setState({ activeDrawer: 'create-release' })}
                button
              >
                <ListItemIcon>
                  <Create />
                </ListItemIcon>
                <ListItemText primary={'Create Release'} />
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: this.state.activeDrawer == 'deploy-release' ? '#eeeeee' : '#ffffff'
                }}
                onClick={() => this.setState({ activeDrawer: 'deploy-release' })}
                button
              >
                <ListItemIcon>
                  <Upload />
                </ListItemIcon>
                <ListItemText primary={'Deploy Release'} />
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: this.state.activeDrawer == 'start-service' ? '#eeeeee' : '#ffffff'
                }}
                onClick={() => this.setState({ activeDrawer: 'start-service' })}
                button
              >
                <ListItemIcon>
                  <NewReleases />
                </ListItemIcon>
                <ListItemText primary={'Start Service'} />
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: this.state.activeDrawer == 'servers' ? '#eeeeee' : '#ffffff'
                }}
                onClick={() => this.setState({ activeDrawer: 'servers' })}
                button
              >
                <ListItemIcon>
                  <StorageIcon />
                </ListItemIcon>
                <ListItemText primary={'Servers'} />
              </ListItem>
            </List>
          </div>
        </Drawer>

        <div className={`${classes.root} flex-container`}>
          <div
            style={{
              flexGrow: 1,
              paddingTop: 64,
              height: '65vh',
              overflowY: 'scroll'
            }}
          >
            {this.getActiveView()}
          </div>
          <Console logs={this.state.logs}></Console>
        </div>
      </main>
    )
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(viewStyles)(Home)
